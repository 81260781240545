.embla {
    height: 100%;
}

.embla__viewport {
    overflow: hidden;
    height: 100%;
    --slide-size: 100%;
    padding: 12px 0;
    max-height: calc(100% - 109px);
}

.embla__viewport-landscapeOnMobile {
    max-height: calc(100% - 71px) !important;
}

.embla__viewport__fullscreen {
    max-height: 100%;
    padding: 0;
}

.embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    height: 100%;
    position: relative;
}

.embla__slide {
    flex: 0 0 var(--slide-size);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.embla__slide__img {
    max-width: 100%;
    height: auto;
    max-height: 100%;
}

.embla-thumbs {
    --thumbs-slide-spacing: 8px;
    --thumbs-slide-height: 90px;
}

.embla-thumbs-hide {
    display: none;
}

.embla-thumbs-landscapeOnMobile {
    --thumbs-slide-height: 52px !important;
}

.embla-thumbs__viewport {
    overflow: hidden;
}

.embla-thumbs__container {
    display: flex;
    flex-direction: row;
    /*margin-left: calc(var(--thumbs-slide-spacing) * -1);*/
    justify-content: center;
}

.embla-thumbs__slide {
    /*flex: 0 0 7%;*/
    min-width: 40px;
    position: relative;
}

.embla-thumbs__slide:not(:first-child) {
    padding-left: var(--thumbs-slide-spacing);
}

.embla-thumbs__slide__button {
    -webkit-appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: block;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 100%;
    transition: opacity 0.2s;
}

.embla-thumbs__slide--selected .embla-thumbs__slide__button {
    opacity: 1;
    border: 2px blue solid;
}

.embla-thumbs__slide__img {
    display: block;
    height: var(--thumbs-slide-height);
    /*width: 100%;*/
    object-fit: cover;
}

.embla__not__loaded__thumb {
    border: none !important;
}

.mantine-ScrollArea-root {
    transform: none !important;
}

@media (max-width: 480px) {
    .embla-thumbs {
        --thumbs-slide-height: 52px;
    }

    .embla__viewport {
        max-height: calc(100% - 71px);
    }
}
