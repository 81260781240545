.dropdownFooter {
    margin: calc(var(--mantine-spacing-md) * -1);
    background-color: var(--mantine-color-gray-0);
    margin-top: var(--mantine-spacing-sm);
    padding: var(--mantine-spacing-md) calc(var(--mantine-spacing-md) * 2);
    padding-bottom: var(--mantine-spacing-xl);
    border-top: 1rem solid var(--mantine-color-gray-1);
}

.headerBackgroundColor {
    background-color: var(--mantine-virtik-default-background-dark-color);
}

.headerDrawerBackgroundColor {
    background-color: #141517;
}

.headerCenter {
    max-width: var(--mantine-virtik-content-max-width);
    height: 100%;
    padding: 0 24px;
    margin: auto;

    & > div {
        width: 33%;
    }
}

.copyButton {
    background-color: var(--mantine-virtik-header-button-background-color);
}

.copiedButton {
    background-color: var(--mantine-virtik-header-copied-button-background-color);
}

.headerButtonContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;

    & > button {
        @media (max-width: 48em) {
            padding: 0 8px;
        }

        @media (max-width: 44em) {
            font-size: 12px;
        }

        @media (max-width: 37em) {
            font-size: 11px;
        }
    }
}

.headerButton {
    color: var(--mantine-virtik-button-text-transparent-color);
}

.authButtonContainer {
    & > button.headerButton  {
        padding-left: 12px;

        @media (max-width: 44em) {
            font-size: 12px;
        }

        @media (max-width: 39em) {
            padding-left: 6px;
        }

        @media (max-width: 37em) {
            font-size: 11px;
        }
    }

    & > .headerButton {
        padding-right: 0;
        padding-left: 0;
    }
}

.projectNameContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.projectName {
    color: var(--mantine-virtik-header-tour-name-text-color);
    font-size: 18px;
    font-weight: 600;
}

.projectNameDrawer {
    padding: 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-style: normal;
    line-height: 155%;
    max-width: 69%;
}

.projectNameHeader {
    display: block;
    text-align: center;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.projectContainerLogo {
    width: 100%;
    float: left;
}

.projectLogo {
    flex: auto;
    width: 100%;
    height: auto;
    max-width: 200px;
    cursor: pointer;
}

.authButtonContainer {
    width: 100%;
    float: right;
}

.burger {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    padding: 0;

    & div, & div:before, & div:after {
        background-color: var(--mantine-virtik-header-tour-name-text-color);
    }
}

.bgDrawer {
    background-color: #000;
}

.drawerButtonContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & button {
        margin-bottom: 24px;
    }
}

.drawerLinks {
    color: #228BE6;
    font-size: 14px;
    cursor: pointer;
    margin-left: var(--mantine-spacing-xl);
    margin-bottom: var(--mantine-spacing-xl);
}

.drawerCopyButton {
    margin-bottom: 24px;
}
