.root {
    height: 100%;
    overflow: hidden;
    padding: 6px 12px;
}

.viewport {
    & >:first-of-type {
        display: block !important;
    }
}

.scrollbar {
    & .mantine-ScrollArea-thumb {
        height: 30px;
        background-color: #707274;
    }

    &, &:hover {
        background-color: #383839;
    }
}
