.block {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px;
    height: 52px;
    background: var(--mantine-virtik-switcher-background-color);
    border: 1px solid var(--mantine-virtik-switcher-icon-default-color);
    z-index: 200;
}

.item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    width: 44px;
    height: 44px;
    opacity: 0.5;
    background: var(--mantine-virtik-switcher-background-color);
    cursor: pointer;
    border-radius: initial;

    &:hover {
        background: var(--mantine-virtik-switcher-icon-hover-color);
        opacity: 1;
    }
    & img {
     width: 24px; /* Firefox fix */
    }
}

.itemActive {
    background: var(--mantine-virtik-button-secondary-ui-color, --mantine-virtik-switcher-background-active-color);
    opacity: 1;
}

.itemInactivate {
    opacity: 0.1;
}
