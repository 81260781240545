.virtikContainer {
    max-width: var(--mantine-virtik-content-max-width);
    width: 100%;
    max-height: var(--mantine-virtik-content-max-height);
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: #FFFFFF;
}

.sceneContainer {
    height: 100%;
    background: #FFFFFF
}

.startContainer {
    height: calc(100vh - 220px);
    background: var(--mantine-virtik-default-background-content-dark-color);
}

.previewContainer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
}

.logoBlock {
    width: 100%;
    height: initial;
    padding: 0 24px;
    max-height: 80%;
}

.buttonBlock {
    height: 50px;

    & button {
        width: 140px;
        height: 100%;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: white;
        font-style: normal;
        padding: 1px 18px;
        background-color: var(--mantine-virtik-header-button-background-color);
        gap: 10px;
        flex: 1 0 0;
        align-self: stretch;

        &:not(:first-of-type) {
            background: var(--mantine-virtik-content-button-background-color);
            margin-left: 30px;

            &:hover {
                background: var(--mantine-virtik-content-button-hover-color);
                opacity: 1;
            }
        }

        @media (max-width: 30em) {
            width: 130px;
        }
    }
}

.errorBody {
    max-width: 450px;
    margin: 16px;
}

.errorButton {
    & > button {
        height: 36px;
        width: 96px;
        font-weight: 400;
    }

    & > button:not(:first-of-type) {
        background: unset;
        color: var(--mantine-virtik-button-text-transparent-color);
        margin-left: 16px;
    }
}

.fullscreenButton {
    position: absolute;
    bottom: 24px;
    right: 24px;
}

.resumeButton {
    margin-right: 20px;
}

.mainBlock {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    min-height: 350px;
    height: calc(100vh - 175px);
    max-height: 850px;
}

.titleBlock {
    max-height: 50px;
    max-width: var(--mantine-virtik-content-max-width);
    width: 100%;
    color: var(--mantine-virtik-default-content-title-color);
}

.startSceneBlock {
    padding: 0.5rem;
}

.resetButton {
    color: #BABBBD;
    background: #434447;

    &:hover {
        background: #707274;
        opacity: 1;
    }
}

.parentBlock {
    overflow: auto;
    min-height: 350px;
    height: calc(100vh - 175px);
    max-height: 850px;

    &::-webkit-scrollbar {
        width: 14px
    }

    &::-webkit-scrollbar-track {
        background: #BABBBD;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--mantine-virtik-default-background-content-dark-color);
        border-radius: 20px;
        border: 3px solid #BABBBD;
    }

    @media (max-width: 48em) {
        height: calc(100vh - 282px);
    }
}

.parentLastVisitedBlock {
    margin-top: 30px;
}

.lastVisitedTitle {
    font-size: 20px;
    color: #0B0B0C;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.2px;

    @media (max-width: 30em) {
        font-size: 18px
    }
}

.childBlock {
    max-width: 920px;
    width: 80%;
    border: 1px solid #E8E8E9;
    margin: 0 auto;

    &:not(:first-child) {
        margin-top: 16px;
    }

    @media (max-width: 30em) {
        width: 100%;
    }
}

.gridBlock {
    padding: 10px 20px;
}

.mantineGridInner {
    display: flex;
    align-items: center;
}

.gridColumnImage {
    text-align: left
}

.gridColumnText > p {
    color: #0B0B0C;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.2px;
}

.gridColumnTourName > p {
    font-weight: 400;

    @media (max-width: 30em) {
        font-size: 12px;
        line-height: normal;
    }
}

.gridColumnDate > p {
    font-weight: 300;

    @media (max-width: 48em) {
        font-size: 16px;
        line-height: normal;
    }

    @media (max-width: 30em) {
        font-size: 10px;
        line-height: normal;
    }
}

.gridColumnRight {
    text-align: right
}

.gridLogo {
    flex: auto;
    width: 100%;
    height: auto;
}

.gridLink {
    color: var(--mantine-virtik-button-outline-color);
    padding: 5px 10px;
    font-weight: 400;
    font-size: 16px;

    &:hover {
        background: #F4F9FE
    }
}

.title404 {
    width: 100%;
    margin: auto;

    & h1 {
        font-weight: 600;
    }
}

.notFoundMessage {
    & h1 {
        font-weight: 700;
    }

    @media (max-width: 30em) {
        text-align: left;
        font-size: 20px;
    }
}

.tryAgain {
    & h1 {
        font-weight: 400;
    }

    @media (max-width: 30em) {
        text-align: left
    }
}
