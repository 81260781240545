.sceneContainer {
    flex: 1 1 auto;
    height: 100%;
    background-color: #f5f5f5;
    position: relative;
}

.sceneBody {
    flex: 1 1 auto;
    height: 100%;
    max-width: 100%;
}

.minimapButton {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 1;

    @media (max-width: 30em) {
        top: 60px;
        right: 10px;
    }
}

.sceneControls {
    position: absolute;
    display: grid;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    & > * {
        margin: 6px 0;
    }
}

.sceneControlsTop {
    position: absolute;
    display: flex;
    top: 24px;
    transform: translateX(50%);
    z-index: 1;
    right: 50% !important;

    & > * {
        margin: 6px 10px;
    }
}

.videoRange {
    position: absolute;
    display: grid;
    bottom: 60px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.videoRangeHotspot {
    max-width: 80%;
    bottom: 15px;
}

.contentUiVisible {
    position: absolute;
    bottom: 24px;

    & > * {
        margin-left: 12px;
    }

    @media (max-width: 30em) {
        position: initial;
    }
}

.videoRangeControls {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px;

    & > * {
        flex: 1 1 auto;
    }

    & > button {
        max-width: 44px;

        @media (max-width: 30em) {
            max-width: 32px;
            max-height: 32px;
        }
    }

    & > button:not(:first-of-type) {
        margin: 0 12px;
    }
}

.videoHiddenControls {
    opacity: 0;
    transition: opacity 0.6s linear;
}

.roundButton {
    padding: 12px;
    background-color: var(--mantine-virtik-content-button-background-color);
    height: 44px;
    width: 44px;
    border: 1px solid var(--mantine-virtik-content-button-hover-color);
    font-size: 0.8rem;
    border-radius: 50%;
    z-index: 1;

    &:hover {
        background: var(--mantine-virtik-content-button-hover-color);
    }

    &:active {
        background: var(--mantine-virtik-button-secondary-ui-color, --mantine-virtik-content-button-active-color);
    }

    &:disabled {
        background: var(--mantine-virtik-content-button-disabled-color);
    }
    & img {
        /*width: 18.7px; !* Firefox fix *!*/
    }
    @media (max-width: 30em) {
        height: 36px;
        width: 36px;
        font-size: 14px;
        padding: 8px;
    }
}

.topMediaScreenUi {
    @media (max-width: 30em) {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.bottomMediaScreenUi {
    @media (max-width: 30em) {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
}

/*    buttonFullScreen: {*/
/*        position: 'fixed',*/
/*        bottom: '44px',*/
/*        right: 24px;*/
/*        opacity: '0.5',*/

/*        '&:hover': {*/
/*            opacity: '1',*/
/*        },*/
/*    },*/

.emulatedFullScreen {
    max-width: none !important;
    width: 100% !important;
    height: 100% !important;
    position: fixed !important;
    max-height: unset;
    top: 0;
    left: 0;
    z-index: 101;

    & > div > div {
        max-width: none !important;
        max-height: none !important;
    }
}

.leftUiVisible {
    right: 24px;
}

.rightUiVisible {
    left: 24px;
}

.leftSceneControls {
    right: 24px;

    @media (max-width: 30em) {
        right: 9px;
    }
}

.hotspotSceneControls {
    @media (max-width: 30em) {
        right: 9px;
    }
}

.hotspotZoomControls {
    @media (max-width: 30em) {
        right: 9px;

        & > * {
            margin: 4px 0;
        }
    }
}

.rightSceneControls {
    left: 24px;

    @media (max-width: 30em) {
        right: 10px;
        left: initial;
    }
}

.leftArrow {
    left: 24px;

    @media (max-width: 30em) {
        left: 9px;
    }
}

.rightArrow {
    right: 24px;

    @media (max-width: 30em) {
        right: 9px;
    }
}

.albumButton {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) !important;
    z-index: 1;

    &:active {
        transform: translateY(-50%);
    }
}

.zIndexForButtons {
    z-index: 110 !important;
}

.albumButtonPositionMinimizeMode {
    top: calc(50% - 53px);

    @media (max-width: 30em) {
        top: 50%;
    }
}

.scrollbarCustomStyle {
    & > div[data-orientation="vertical"] {
        display: none;
    }

    & > div[data-orientation="horizontal"] {
        height: 6px;
        padding: 0;
        width: 100%;
        border-radius: 5px;
        background-color: #BABBBD
    }

    & .mantine-ScrollArea-corner {
        display: none;
    }
}
