.sidebarMenu {
    font-family: Inter !important;
    font: Inter !important;
    position: absolute;
    z-index: 105;
    height: auto;
    top: 0;
    max-height: 100%;

    @media (max-width: 30em) {
        width: 100%;
    }
}

.sidebarMenuNavbar {
    background-color: rgba(22, 23, 24, 0.8);
    text-align: left;
    position: static;
    padding: 24px 0;
    height: auto;
}

.listMenuItems {
    font-family: Inter !important;

    & > img.borderItem {
        margin-top: 0;

        @media (max-width: 30em) {
            padding-right: 92px;
        }
    }
}

.sidebarLogo {
    padding: 0 24px;

    & > img {
        height: 80px;
    }
}

.borderItem {
    padding: 0 24px;
    color: #fff;
    margin-top: 16px;
    cursor: default;
    word-break: break-word;
}

.sidebarBody {
    font-family: Inter !important;
    color: #fff;
    display: grid;
    align-items: center;
    overflow: auto;
}

nameBody {
    font-family: Inter !important;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
}

.descriptionBody {
    font-family: Inter !important;
    color: #BABBBD;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.dateBody {
    font-family: Inter !important;
    font-size: 12px;
    font-weight: 600;
    line-height: 19px;
}

.sidebarOptionsBox {
    overflow: hidden;
    font-family: Inter !important;
}

.sidebarOptionsItem {
    color: #fff;
    font-size: 22px;
    line-height: 19px;

    &:hover {
        background-color: var(--mantine-virtik-button-secondary-ui-color, --mantine-virtik-switcher-background-active-color);
        opacity: 0.6;
    }

    &:active {
        background-color: var(--mantine-virtik-button-secondary-ui-color, --mantine-virtik-switcher-background-active-color);
        opacity: 1;
    }
}

.sidebarOptionsBody1 {
    padding: 0 32px !important;
}
.sidebarOptionsBody2 {
    padding: 0 64px !important;
}

.roundButton {
    padding: 12px;
    background-color: #434447;
    height: 44px;
    width: 44px;
    font-size: 0.8rem;
    border-radius: 0;
    border: none;

    &:hover {
        background: #707274;
    }

    &:active {
        background: var(--mantine-virtik-button-secondary-ui-color, --mantine-virtik-content-button-active-color);
    }

    &:disabled {
        background: #161718;
    }

    @media (max-width: 30em) {
        height: 36px;
        width: 36px;
        font-size: 14px;
        padding: 8px;
    }
}

.buttonArea {
    z-index: 1;
    position: absolute;

    @media (max-width: 30em) {
        right: 24px;
        top: 24px;
        left: initial;
    }
}

.buttonAreaShowPosition {
    top: 0;
}

.buttonAreaHidePosition {
    top: 10px !important;
    @media (max-width: 30em) {
        left: 0;
        right: initial;
    }
}

.interFont {
    font-family: Inter;
}

/*side style*/


/*    left*/
.leftMenuSide {
    width: 320px;
    left: 0;
}

.leftButtonAreaShowPosition {
    left: 320px;
}

.leftButtonAreaHidePosition {
    left: 0;
}


/*     right*/
.rightMenuSide {
    width: 320px;
    right: 0;
}

.rightButtonAreaShowPosition {
    right: 320px;
}

.rightButtonAreaHidePosition {
    right: 0;
}


/*     top*/


/*     bottom*/
