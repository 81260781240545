.slick-prev:before, .slick-next:before {
    color: #000000 !important;
}

.slick-next {
    transform: translate(0, -51%);
    z-index: 1000;
    right: -45px;
}

.slick-prev {
    transform: translate(0, -51%);
    z-index: 1000;
    left: -45px;
}

.slick-arrow {
    width: 45px;
    height: 100%;
    background-color: #434447;
    border: 1px solid #383839;
    pointer-events: auto;
}

.slick-arrow:hover {
    background-color: #434447;
}

.slick-slider {
    margin: 0 auto;
    cursor: pointer;
}

.slick-prev:before {
    display: block;
    margin-top: 10px;
    content: url("../../../assets/images/left_arrow.png");
}

.slick-next:before {
    display: block;
    margin-top: 10px;
    content: url("../../../assets/images/right_arrow.png");
}

.current_location {
    margin-right: 5px;
    display: none !important;
}

.slick-track {
    pointer-events: auto;
}
