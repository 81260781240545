.navPlanContent {
    z-index: 104;
    position: absolute;
    /*top: -45px;*/
    width: 100%;
    pointer-events: none;
}

.navPlanContentChild {
    position: relative;
}

.navSlide {
    height: 44px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 115px;
    background-color: rgba(67, 68, 71, 0.9);
    color: #BABBBD;
    border: 1px solid #383839;
    padding: 8px 0;
}

.arrowSlide {
    position: absolute;
    /*top: 0;*/
    width: 45px;
    height: 100%;
    background-color: #434447;
    border: 1px solid #383839;
}

.arrowLeft {
    left: -45px;
}

.arrowRight {
    right: -45px;
}

.selectedPlan {
    background-color: var(--mantine-virtik-button-secondary-ui-color, --mantine-virtik-content-button-active-color);
    color: #FFFFFF;
}

.currentLocation {
    margin-right: 5px;
    margin-left: 5px;
    vertical-align: sub;
    display: none !important;
}

.attachedPlanIcon {
    display: inline !important;
}
