.appBody {
    text-align: center;
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
}

.contentBody {
    height: 100%;
    position: relative;
}

.overlayBody {
    max-width: var(--mantine-virtik-content-max-width);
    max-height: var(--mantine-virtik-content-max-height);
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.customOverlay {
    background-color: #262626;
    opacity: 0.5;
}
