.contentArea {
    max-width: var(--mantine-virtik-content-max-width);
    max-height: var(--mantine-virtik-content-max-height);
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    display: flex;
    background-color: var(--mantine-virtik-default-background-content-dark-color);
}

.viewerContainer {
    flex: 1 1 auto;
    height: 100%;
    min-height: min(400px, 100%);
}

.sceneArea {
    position: relative;
    flex: 1 0 auto;
    height: 100%;
    min-height: 300px;
    overflow: hidden;
}

.sidebarHeader {
    font-size: 26px;
    border-bottom: 2px solid white;
}

.sidebarBody {
    display: grid;
    align-items: center;
    overflow: auto;
}

.sidebarBodyItem {
    font-size: 22px;
}
    /*.sidebarUl: {*/
    /*    listStyleType: 'none',*/
    /*    margin: '0',*/
    /*    padding: '0',*/
    /*    wordWrap: 'break-word',*/
    /*    maxHeight: '23vw',*/
    /*    overflow: 'auto',*/
    /*}*/

.sidebarLi {
    border-bottom: 4px solid #20e320;
    cursor: pointer;
    font-size: 14px;
    color: #5e0909;
    padding: 2px;
}

.bottomSceneSwitcher {
    position: absolute;
    left: calc(50% - 184px/2);
    width: 184px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    bottom: 24px;
}

.bottomScene2DPlanSwitcher {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px;
    /*position: relative;*/
    position: absolute;
    /*bottom: 84px;*/
    bottom: 107px;
    width: 100%;
}

.modalWindowModeNotifyBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(22, 23, 24, 0.90);
    position: absolute;
    color: white;
    height: 100%;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
}

.modalWindowModeNotifyText {
    color: #FFF;
    text-align: center;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    margin-top: 16px;
    padding: 0 10%;
    margin-bottom: 40px;
}

.modalWindowModeNotifyImage {
    border: 1px solid #fff;
}

.modalWindowModeNotifyButton {
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 34px;
    padding: 1px 18px;
}
