.react-pdf__Page__canvas {
    margin: 0 auto;
}
.react-pdf__Page {
    background-color: #383839 !important;
}

.react-pdf__message--error {
    display: none;
}
