.minimap {
    position: absolute;
    top: 24px;
    right: 80px;
    z-index: 1;
}

.minimapSmallSize {
    width: 160px;
    height: 90px;

    @media (min-width: 75.01em) and (max-height: 47em) {
        width: 320px;
        height: 180px;
    }

    @media (max-width: 75em) {
        width: 160px;
        height: 90px;
    }

    @media (max-width: 48em) {
        width: 320px;
        height: 180px;
    }

    @media (max-width: 30em) {
        width: 188px;
        height: 105px;
        right: 60px;
    }
}

.minimapBigSize {
    /*width: 687px;*/
    height: 386px;

    @media (min-width: 75.01em) and (max-height: 47em) {
        /*width: 864px;*/
        height: 485px;
    }

    @media (max-width: 75em) {
        /*width: 687px;*/
        max-height: 320px;
        height: 386px;
    }

    @media (max-width: 48em) {
        /*width: 602px;*/
        height: 338px;
    }

    @media (max-width: 30em) {
        /*width: 252px;*/
        height: 182px;
        right: 60px;
    }
}

.minimapImage {
    width: 100%;
    height: 100%;
}

.hotspotImage {
    width: 24px;
}

.childMinimap {
    position: relative;
}
