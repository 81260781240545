.footer {
    width: 100%;
}

.inner {
    max-width: var(--mantine-virtik-content-max-width);
    margin: auto;
    text-align: justify;
    padding: 12px 24px;
}

.footerText > p {
    color: #1A1B1E;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%;
}

.footerLinksUl {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 48em) {
        display: block;
    }
}

.footerLinksLi {
    text-align: center;
    list-style-type: none;
    display: inline-block;
    line-height: 1;

    & div > span > a {
        color: #868E96;
    }

    @media (max-width: 48em) {
        text-align: left;
        display: block;

        &:not(:first-child) {
            padding-top: 10px;
        }
    }
}
