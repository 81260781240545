.innerElementOpenModal {
    position: absolute !important;
    left: 0;
}

.contentElementOpenModal {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.bodyElementOpenModal {
    height: 100%;
    overflow: hidden;
}

.currentPlanContent {
    position: relative;
    height: 100%;
    overflow: hidden;
}

.hotspotPosition {
    cursor: pointer;
    position: absolute;
}

.child {
    position: relative;
}

.currentImageTag {
    /*height: 100%;*/
}

.hotspotImagePosition {
    display: block;
    margin-top: -50%;
    margin-left: -50%;
}

.overlayElementOpenModal {
    position: absolute !important;
}

.polygon {
    position: absolute;
    width: 100%;
    height: 100%;
}

.polygonNew {
    position: absolute;
}

.shape {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}
