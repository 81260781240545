.customBodyElement {
    min-width: 200px;
    width: 100%;
    height: 100%;
    background: #383839;
}

.customInnerElement {
    position: absolute;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.customOverlay {
    position: absolute;
}

.customContentElement {
    border: 2px solid #BABBBD;
    box-shadow: 0 6.410256385803223px 12.820512771606445px 0 #000000CC;
    overflow-y: hidden;
    max-height: min(410px, 100%);
    max-width: 650px;
    height: 100%;
    min-height: min(410px, 100%);
    display: flex;
    width: 100%;

    @media (max-width: 48em) {
        border: 1px solid #BABBBD;
    }
}

.customContentElementMaximize {
    max-height: 660px;
    max-width: 1000px;
    height: 100%;

    @media (min-width: 75.01em) and (max-height: 47em) {
        max-height: 90%;
    }

    @media (max-width: 75em) {
        max-height: 411px;
        max-width: 624px;
    }

    @media (max-width: 48em) {
        max-height: 52%;
        max-width: 95%;
    }

    @media (max-width: 30em) {
        max-height: 235px;
        min-height: unset;
        max-width: 95%;
    }
}

.customContentElementMaximizeLandscapeOnMobile {
    max-width: 444px;
    max-height: 293px;
    min-height: unset;
}

.hotspotControls {
    font-family: Inter !important;
    z-index: 11;
    position: absolute;
    top: 24px;
    right: 24px;

    & > button:not(:last-of-type) {
        margin-right: 12px;
    }

    @media (max-width: 30em) {
        top: 9px;
        right: 9px;

        & > button:not(:last-of-type) {
            margin-right: 8px;
        }
    }
}

.logo360 {
    position: absolute;
    top: 24px;
    left: 24px;

    & > img::selection {
        background-color: transparent;
    }

    @media (max-width: 30em) {
        top: 9px;
        left: 9px;
    }
}

.modalVideo360 {
    margin: 0 auto;
    max-width: 100%;
    height: 100%;
}

.modalVideo {
    margin: 0 auto;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000;
}

.modalImage {
    margin: 0 auto;
    max-width: 100%;
    height: 100%;
}

.modalImage360 {
    height: 100%;
    width: 100%;
    margin: 0 auto;
    max-width: 100%;
    display: block;
}

.hotspotVideoPlayer {
    max-height: 100%;
    display: flex;
    height: auto;
}

.rootElementOpenModal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
}

.innerElementOpenModal {
    height: 100%;
    display: block;
}

.contentElementOpenModal {
    width: 100%;
    height: 100%;
    max-width: unset;
    max-height: unset;
    background: #383839;
}

.videoHotspot {
    width: 100%;
    height: auto;
    max-height: 100%;
}

.contentElementOpenModalAlbum {
    max-width: unset;
    max-height: unset;
}

.mobileHotspotPdfControls {
    height: 50px;
    display: flex;
    padding: 0 12px;
    justify-content: flex-end;
    align-items: center;
    background-color: #434447;

    & > span {
        color: #707274;
    }

    & > button {
        width: 32px;
        height: 32px;
        padding: 6px;
        font-size: 10px;
        border: none !important;
    }

    & > button:not(:last-child), & > span {
        margin-right: 12px;
    }

    & > button:last-child {
         background: var(--mantine-virtik-content-button-hover-color);
    }
}

.pdfMobileDocument {
    overflow: auto;
}

.hotspotPdfControls {
    font-family: Inter;
    z-index: 1011;
    position: absolute;
    top: 7px;
    right: 16px;
    display: flex;
    flex-direction: row-reverse;
    justify-items: center;
    align-items: center;

    & > button:not(:first-of-type) {
        margin-right: 12px;
    }

    & > span {
        margin-right: 12px;
        color: #707274;
    }

    @media (max-width: 30em) {
        top: 9px;
        right: 9px;

        & > button {
            width: 32px;
            height: 32px;
            padding: 6px;
            font-size: 10px;
            border: none !important;
        }

        & > button:not(:first-of-type) {
            margin-right: 5px;
        }

        & > span {
            margin-right: 5px;
        }
    }
}

.pdfBody {
    height: calc(100% - 58px);

    @media (max-width: 30em) {
        height: calc(100% - 50px);
    }
}

.customBodyElementFullscreen {
    padding: 0 24px;

    @media (max-width: 30em) {
        padding: 0 9px;
    }
}

.customHeaderPdfElement {
    position: absolute !important;
    width: 100%;
    height: 58px;
    background-color: #383938 !important;
    border-bottom: 1px solid #707274 !important;

    & > button {
        display: none;
    }

    @media (max-width: 30em) {
        height: 50px;
    }
}

.customContentPdfElement {
    align-items: center;
    flex-wrap: wrap;
    background-color: #383839;
}

.pdfWrapper {
    max-width: 100%;
}

.pdfOnePage {
    &:not(:first-of-type) {
        margin: 5px 0 0 0;
    }
}

.percentValue {
    width: 35px;
}

.contentDisableBorder {
    border: none !important;
}

.navigationButtonContainer {
    position: absolute;
    bottom: 12px;
    right: 12px;

    & button:not(:first-child) {
        margin-left: 6px;
    }
}

.pdfNavigationButton {
    background-color: #707274;
    border-radius: 5px;
}

.hotspotPlayButtonForVideoModal {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);

    &:active {
        transform: translate(-50%, -50%);
    }
}

.preTextContent {
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: left;
}
